import {
    useState,
    useEffect,
    useRef
} from "react";
import {
    Navigate,
    useParams,
    useNavigate
} from "react-router-dom";
import {
    Box,
    Typography,
    Container,
    Grid,
    Paper,
    Skeleton,
    IconButton,
    TextField,
    FormGroup,
    FormControlLabel,
    Checkbox,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableHead,
    TableSortLabel,
    Button,
    Tooltip,
    List,
    ListItemButton,
    ListItemText,
    ListItem,
    Chip,
    ListItemIcon
} from '@mui/material';
import SectionTitle from "../../components/common/SectionTitle";
import ClientPosMappingSum from "../../components/client/pos/ClientPosMappingSum";
import ClientPosMappingUpload from "../../components/client/pos/ClientPosMappingUpload";
import CurrentClientContext from "../../providers/CurrentClientProvider";
import ClientService from "../../services/ClientService";
import EditIcon from '@mui/icons-material/Edit';
import constant from "../../constants/constant";
import MessageContext from "../../providers/GlobalTip";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import AuthProvider from "../../providers/AuthProvider";

const jss = {
    noteMessage: {
        '.MuiListItemText-primary': {
            wordBreak: 'break-all',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 1,
            overflow: 'hidden',
            fontSize: 12,
            whiteSpace: 'pre-wrap'
        }
    },
    posStyles: {
        '.MuiListItemText-primary': {
            wordBreak: 'break-all',
            fontSize: 12,
            whiteSpace: 'pre-wrap',
            pr: 1
        },
        '.MuiListItemText-secondary': {
            wordBreak: 'break-all',
            fontSize: 12,
            whiteSpace: 'pre-wrap'
        }
    },
    listStyle: {
        backgroundColor: 'white',
        maxHeight: '60vh',
        minHeight: '50px',
        overflowY: 'scroll'
    }
}

function ClientPosMapping(props) {
    const currentClientContext = CurrentClientContext.useClient()
    const [locations, setLocations] = useState([])
    const [classes, setClasses] = useState([])
    const [posList, setPosList] = useState([])
    const [clientPosList, setClientPosList] = useState([])
    const [locationList, setLocationList] = useState([])
    const [editPos, setEditPos] = useState(false)
    const [checkedPos, setCheckedPos] = useState([])
    const [selectedDepartment, setSelectedDepartment] = useState(null)
    const [departmentLocationMapping, setDepartmentLocationMapping] = useState([]);
    const [pickedClassId, setPickedClassId] = useState(0)
    const [statistics, setStatistics] = useState({})
    const [posCodes, setPosCodes] = useState({})
    const messageContext = MessageContext.useMessage();
    const [locationPosCodeSortConfig, setLocationPosCodeSortConfig] = useState({ key: 'location_code', direction: 'descending' })
    const [togglingMappedDepartment, setTogglingMappedDepartment] = useState(false)

    const auth = AuthProvider.useAuth()
    const [isAdmin, setIsAdmin] = useState(false)

    // is admin
	useEffect(() => {
		// role-create marks admin, but we don't have role specific checks currently
		if (auth.checkPermission('role-create')) {
			setIsAdmin(true)
		}
	}, [auth])

    useEffect(() => {
        if( currentClientContext.client ) {
            getPosClientData()
            getClientPosList()
            getClientLocations()
            getDepartmentLocationMapping()
        }
    }, [currentClientContext.client])

    useEffect(() => {
        if (selectedDepartment) {
            // from selected class and department, get locations
            let departmentId = selectedDepartment.Id
            let classId = pickedClassId

            if (!departmentLocationMapping[departmentId + '-' + classId]) {
                setLocationList([])
                return
            }

            const mapping = [...new Set(departmentLocationMapping[departmentId + '-' + classId])]

            let mappedLocations = locations.filter(loc => {
                return mapping.includes(parseInt(loc.client_location_id))
            })

            setLocationList(mappedLocations)
        }
    }, [selectedDepartment])

    function updatedFile() {
        getPosClientData()
        getClientPosList()
        getClientLocations()
        getDepartmentLocationMapping()
        resetSelections()
    }

    function resetSelections() {
        setPickedClassId(null)
        setPosList([])
        setLocationList([])
        setSelectedDepartment(null)
    }

    /**
     * Fetches department location mapping.
     * 
     * Maps department to locations
     * Indexed by department id-class id, values are array of location ids connected to the department/class combo
     */
    async function getDepartmentLocationMapping() {
        try {
            const client = currentClientContext.client.Id
            let response = await ClientService.getDepartmentLocationMapping(client)
            setDepartmentLocationMapping(response)
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * Toggles sort on location pos table column
     * 
     * @param {*} key Key to sort by
     * @param {*} direction Sort direction
     */
    const requestSortLocationPosTable = (key, direction = null) => {
        if (!direction) {
            direction = 'ascending';
            if (locationPosCodeSortConfig.key === key && locationPosCodeSortConfig.direction === 'ascending') {
                direction = 'descending';
            }
        }
        setLocationPosCodeSortConfig({ key, direction });

        if (locationPosCodeSortConfig.key) {
            if (key !== 'pos_code') {
                let sortedLocations = [...locations].sort((a, b) => {
                    let aValue = parseInt(a[locationPosCodeSortConfig.key]);
                    let bValue = parseInt(b[locationPosCodeSortConfig.key]);
            
                    if (aValue < bValue) {
                        return locationPosCodeSortConfig.direction === 'ascending' ? -1 : 1;
                    }
                    if (aValue > bValue) {
                        return locationPosCodeSortConfig.direction === 'ascending' ? 1 : -1;
                    }
                    return 0;
                });
                setLocations(sortedLocations);
            } else {
                console.log(locations[0].ClientMapping)
                // let sortedLocations = [...locations].sort((a, b) => {
                //     let aValue = a.ClientMapping.
                //     let bValue = b[locationPosCodeSortConfig.key].toLowerCase();
            
                //     if (aValue < bValue) {
                //         return locationPosCodeSortConfig.direction === 'ascending' ? -1 : 1;
                //     }
                //     if (aValue > bValue) {
                //         return locationPosCodeSortConfig.direction === 'ascending' ? 1 : -1;
                //     }
                //     return 0;
                // });
                // setLocations(sortedLocations);
            }
        }
    };

    async function getClientLocations() {
        const client = currentClientContext.client.Id
        let response = await ClientService.getClientLocations(client)
        let tmpPos = {}
        response.map(loc => {
            tmpPos[loc.client_location_id] = loc.ClientMapping
        })

        // sort locations by location code as default
        response.sort((a, b) => {
            a = parseInt(a.location_code)
            b = parseInt(b.location_code)
            if (a < b) {
                return -1;
            }
            if (a > b) {
                return 1;
            }
            return 0;
        });

        setPosCodes(tmpPos)
        setLocations(response)
    }
    async function getPosClientData() {
        const client = currentClientContext.client.Id
        let response = await ClientService.getPosClassMapData(client)
        setClasses(Object.values(response['mapping']))
        setStatistics(response.statistics)
    }

    async function getClientPosList() {
        const client = currentClientContext.client.Id
        let response = await ClientService.getClientPosList(client)
        setClientPosList(Object.values(response))

    }

    async function togglePosMapping(pos, pickedClassId) {
        const client = currentClientContext.client.Id
        setTogglingMappedDepartment(true)
        try {
            await ClientService.togglePosClassesMapping(client, pickedClassId, pos.Id)

            // update pickedClassId pos list
            let _posList = posList
            if (_posList.indexOf(pos) !== -1) {
                _posList = _posList.filter(p => p !== pos)
            } else {
                _posList.push(pos)
            }
            setPosList(_posList)

            // update class list with new pos_list
            let _classes = classes
            _classes.map(_class => {
                if (_class.id === pickedClassId) {
                    _class.pos_list = _posList
                }
            })
            setClasses(_classes)
        } catch (error) {
            console.error(error)
            messageContext.show("Something went wrong toggling the POS mapping", 5)

            setCheckedPos()
        }
        setTogglingMappedDepartment(false)
    }

    function clickToClass(item) {
        resetSelections()
        setPickedClassId(item.id)
        setPosList(Object.values(item.pos_list))
        setEditPos(false)
    }

    function editPosAction() {
        if (!pickedClassId) return;
        setSelectedDepartment(null)
        setLocationList([])
        setEditPos(!editPos)
    }

    function updatePosMapper(pos) {
        togglePosMapping(pos, pickedClassId)
    }

    const common_input_props = {
        fullWidth: true,
        variant: "outlined",
        size: "small",
        sx: {mt:2},
    }

    async function updatePOS(newPos, loc) {
        if (newPos == loc.ClientMapping) return;

        const response = await ClientService.updateLocationPos(loc.clientid, loc.client_location_id, newPos)
        setPosCodes({
            ...posCodes,
            [loc.client_location_id]: newPos
        })

        let _l = locations
        _l.map(loc => {
            if (loc.client_location_id == response.client_location_id) {
                loc.ClientMapping = response.ClientMapping
            }
        })

        setLocations(_l)
        messageContext.showSuccess('POS Updated')
    }

    function setPos(e, loc) {
        setPosCodes({
            ...posCodes,
            [loc.client_location_id]: e.target.value
        })
    }

    return <Box>
        <Grid container spacing={2}>
            <Grid item lg={3} xs={12} sx={{mt: 2}}>
                <ClientPosMappingUpload success={updatedFile} isAdmin={isAdmin} />
            </Grid>
            <Grid item lg={9} xs={12} sx={{mt: 2}}>
                <ClientPosMappingSum statistics={statistics} />
            </Grid>
        </Grid>
        <Grid container spacing={2} sx={{mt: 0}}>
            <Grid item lg={3} md={12} xs={12}>
                <Paper elevation={2}>
                    <SectionTitle
                        label="Location POS Code"
                    />
                    <TableContainer sx={{maxHeight: 567}}>
                        <Table size="small" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Location</TableCell>
                                    <TableCell align='right' onClick={() => requestSortLocationPosTable('location_code')}>
                                        <Box display='block' textAlign='center'>
                                            Code
                                            {locationPosCodeSortConfig.key === 'location_code'
                                                ? locationPosCodeSortConfig.direction === 'descending'
                                                    ? <ArrowDownwardIcon />
                                                    : <ArrowUpwardIcon />
                                            : <UnfoldMoreIcon />}
                                        </Box>
                                    </TableCell>
                                    <TableCell align='right' onClick={() => requestSortLocationPosTable('ClientMapping')}>
                                        <Box display='block' textAlign='center'>
                                            POS Code
                                            {locationPosCodeSortConfig.key === 'ClientMapping'
                                            ? locationPosCodeSortConfig.direction === 'descending'
                                                    ? <ArrowDownwardIcon />
                                                    : <ArrowUpwardIcon />
                                            : <UnfoldMoreIcon />}
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {locations.map(loc => {
                                    if (!loc.location_name) return false;
                                    return (
                                        <TableRow 
                                            key={'loc_list_' + loc.client_location_id}
                                            sx={{maxHeight: 45, height: 45}}
                                        >
                                            <TableCell sx={{whiteSpace: 'nowrap', my: 0, py: 0, maxHeight: 45}}>
                                                {loc.location_name}
                                            </TableCell>
                                            <TableCell align='right' sx={{whiteSpace: 'nowrap', my: 0, py: 0, maxHeight: 45}}>{loc.location_code}</TableCell>
                                            <TableCell align='right' sx={{whiteSpace: 'nowrap', my: 0, py: 0, maxHeight: 45}}>
                                                {isAdmin ?
                                                    <TextField
                                                        {...common_input_props}
                                                        label="POS Code"
                                                        name="pos_code"
                                                        value={posCodes[loc.client_location_id] || ''}
                                                        onChange={e => {setPos(e, loc)}}
                                                        onBlur={e => {updatePOS(e.target.value, loc)}}
                                                        sx={{whiteSpace: 'nowrap', my: 1, py: 0, maxHeight: 45}}
                                                    />
                                                    : loc.ClientMapping}
                                            </TableCell>
                                        </TableRow>
                                )})}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Grid>
            <Grid item md={3} xs={12}>
                <Paper elevation={2}>
                    <SectionTitle
                        label={`Orbit Classes (${classes.length})`}
                    />
                    <List sx={jss.listStyle} dense>
                        {classes?.map(_class => {
                            return  <ListItem
                                key={_class.code}
                                selected={_class.id === pickedClassId}
                                sx={{
                                    fontSize: 12,
                                    marginBottom: '5px',
                                    height: '40px'
                                }}
                                secondaryAction={<Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <Typography sx={{marginRight: '10px', fontSize: 12}}>{_class.code}</Typography>
                                    <Tooltip title={_.invert(constant.SERVICE_TYPE_ID)[_class.service_type_id]}>
                                        <Chip sx={{backgroundColor: 'rgba(0, 0, 0, 0.01)'}} label={_.invert(constant.SERVICE_TYPE_ID)[_class.service_type_id].substring(0,1)} />
                                    </Tooltip>
                                    </Box>
                                }
                            >
                                <ListItemButton key={`orbit_class_${_class.code}`} onClick={e => clickToClass(_class)}>
                                    <ListItemText sx={jss.noteMessage} primary={_class.name}/>
                                </ListItemButton>
                            </ListItem>
                        })}
                    </List>
                </Paper>
            </Grid>
            <Grid item md={3} xs={12}>
                <Paper elevation={2}>
                    <SectionTitle
                        label={`POS Classes Mapped (${!editPos ? checkedPos.length : clientPosList.length})`}
                        rightComponent={isAdmin ?
                            <Tooltip title="Edit POS">
                                <IconButton component="a" onClick={editPosAction} target="_blank" sx={{color: '#666'}}>
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                        : null}
                    />
                    <List sx={jss.listStyle} dense>
                        {!editPos ? posList?.map(pos => {
                            return <ListItem
                                key={'pos_' + pos.Id}
                                sx={{
                                    fontSize: 11
                                }}
                                disablePadding
                            >
                            <ListItemButton
                                key={`pos_class_${pos.Id}`}
                                onClick={() => setSelectedDepartment(pos)}
                                sx={{
                                    backgroundColor: pos.Id === selectedDepartment?.Id ? '#f0f0f0' : 'inherit',
                                }}
                            >
                                <ListItemText sx={jss.posStyles} primary={pos.Name.toUpperCase()} />
                                <ListItemText sx={jss.posStyles} secondary={pos.Code.toUpperCase()} />
                            </ListItemButton>
                            </ListItem>
                        }) :
                            clientPosList.map(cPos => {
                                return <ListItemButton key={'c_pos_' + cPos.Id} role={undefined} dense>
                                    {isAdmin ?
                                        <ListItemIcon>
                                            <Checkbox
                                                key={'c_pos_check_' + cPos.Id}
                                                edge="start"
                                                checked={posList.some(pos => parseInt(pos.Id) === parseInt(cPos.Id))}
                                                onChange={() => updatePosMapper(cPos)}
                                                disabled={togglingMappedDepartment}
                                                tabIndex={-1}
                                                inputProps={{ 'aria-labelledby': cPos.Id }}
                                            />
                                        </ListItemIcon>
                                    : null}
                                    <ListItemText id={cPos.Id} primary={cPos.Name.toUpperCase() + ' (' + cPos.Code + ')'} />
                                </ListItemButton>
                            })
                        }
                    </List>
                </Paper>
            </Grid>
            {locationList.length > 0 && <Grid item md={3} xs={12}>
                <Paper elevation={2}>
                    <SectionTitle
                        label={`Locations (${locationList.length})`}
                    />
                    <List sx={{
                        backgroundColor: 'white',
                    }} dense>
                        {locationList?.map(loc => {
                            return <ListItem
                                key={loc.location_code}
                                sx={{
                                    fontSize: 11
                                }}
                                secondaryAction={
                                    loc.location_code
                                }
                                disablePadding
                            >
                                <ListItemButton key={`location_${loc.client_location_id}`} >
                                    <ListItemText sx={jss.noteMessage} primary={loc.location_name}/>
                                </ListItemButton>
                            </ListItem>
                        })}
                    </List>
                </Paper>
            </Grid>}
        </Grid>
    </Box>
}

export default ClientPosMapping