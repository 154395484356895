import {
	useState,
	useEffect,
	useRef
} from "react";
import {
	Navigate,
	useParams,
} from "react-router-dom";
import {
	Box,
	Paper,
	Skeleton,
	IconButton,
	TextField,
	FormGroup,
	FormControl,
	Checkbox,
	TableContainer,
	Table,
	TableBody,
	TableRow,
	TableCell,
	TableHead,
	TableSortLabel,
	Typography,
	Button,
	Menu,
	MenuList,
	MenuItem,
	Alert,
	Grid,
	InputAdornment,
	Fade,
	Collapse,
	FormControlLabel,
	Switch,
	Tooltip,
	CircularProgress,
	InputLabel,
	NativeSelect,
} from '@mui/material';
import {
	LoadingButton
} from '@mui/lab'
import AuthContext from '../../providers/AuthProvider'
import SectionTitle from '../../components/common/SectionTitle'
import constants from '../../constants/constant'
import ClientService from '../../services/ClientService'
import PosService from '../../services/PosService'
import AssociateMarketService from '../../services/AssociateMarketService'
import _ from 'underscore'
import moment from 'moment'
import TipContext from '../../providers/GlobalTip'
import SaveTwoToneIcon from '@mui/icons-material/SaveTwoTone';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import { AdapterMoment  } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker, DatePicker } from '@mui/x-date-pickers';
import {associateMarketerList, posSystemList, fiscalYearList} from './setup/steps/constants'
import AddStatusModal from './AddStatusModal';
import CheckIcon from '@mui/icons-material/Check';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

const jss = {
	tableSection: {
		p: 2,
		pt: 0,
	},
	tableLabel: {
		fontWeight: 'bolder',
		width: '25%',
		textAlign: 'right'
	},
	editTitle: {
		fontSize: 16,
		fontWeight: 'bolder',
		mt: 3,
	},
	refreshLink: {
		fontSize: 10,
		mt: 0.5,
		color: 'primary.main',
		cursor: 'pointer',
	},
	textField: {
		lineHeight: '38px',
		fontSize: 12,
	},
}

const requiredFields = [
	'ClientCode',
	'Name',
	'FiscalYearType',
]

function ClientProfile(props) {
	const { client, isAdmin } = props
	const more_element = useRef()
	const [open_more, setOpenMore] = useState(false)
	const tipContext = TipContext.useMessage()
	const [editing, setEditing] = useState(false)
	const [has_change, setHasChange] = useState(false)
	const auth_context = AuthContext.useAuth()
	const [form_data, setFormData] = useState()
	const [saving, setSaving] = useState(false)
	const [is_approved, setIsApproved] = useState(false)
	const [add_status, setAddStatus] = useState(false)

	useEffect(() => {
		if( !_.isEmpty(client) ) {
			if( client.ApprovedDate ) {
				setIsApproved(true)
			}

			// if clietn data processing exception is not null and is not object, parse the string to an object
			if( client.DataProcessingException && typeof client.DataProcessingException === 'string' ) {
				client.DataProcessingException = JSON.parse(client.DataProcessingException)
			}

			setFormData({
				ClientCode: client.ClientCode,
				Name: client.Name,
				LegalName: client.LegalName,
				Address1: client.Address1,
				Address2: client.Address2,
				City: client.City,
				State: client.State,
				ZipCode: client.ZipCode,
				Province: client.Province,
				Country: client.Country,
				Email: client.Email,
				Phone: client.Phone,
				Mobile: client.Mobile,
				instagram: client.instagram,
				Website: client.Website,
				Twitter: client.Twitter,
				Facebook: client.Facebook,
				tiktok: client.tiktok,
				linkedin: client.linkedin,
				AssociateMarketerId: client.AssociateMarketerId,
				PointOfSaleId: client.PointOfSaleId,
				FiscalYearType: client.FiscalYearType,
				FiscalYearDate: client.FiscalYearDate,
				Imu: client.Imu,
				HasForeignCurrency: client.HasForeignCurrency,
				CompanyFounded: client.CompanyFounded,
				AuthKey: client.AuthKey,
				ReferredBy: client.ReferredBy,
				Notes: client.Notes,
				CompetitionNotes: client.CompetitionNotes,
				ActiveDate: client.ActiveDate,
				BillingStartDate: client.BillingStartDate,
				IsBillingManagementOne: client.IsBillingManagementOne,
				OtbRate: client.OtbRate,
				PnotbRate: client.PnotbRate,
				NotbRate: client.NotbRate,
				OtbSetupRate: client.OtbSetupRate,
				PnotbSetupRate: client.PnotbSetupRate,
				NotbSetupRate: client.NotbSetupRate,
				HasShuttle: client.HasShuttle,
				ShuttleRate: client.ShuttleRate,
				HasHeadCount: client.HasHeadCount,
				IsTest: client.IsTest,
				IsManualEntry: client.IsManualEntry,
				ApprovedDate: client.ApprovedDate,
				IsBeta: client.IsBeta,
				ApiKey: client.ApiKey,
				ApiUrl: client.ApiUrl,
				ApiField: client.ApiField,
				DataProcessingException: client.DataProcessingException ? client.DataProcessingException : {},
				DeletedDate: client.DeletedDate,
			})
		}
	}, [client])

	useEffect(() => {
		if( is_approved ) {
			updateFormData({target:{name: 'ApprovedDate', value:moment().format('YYYY-MM-DD')}})
		} else {
			updateFormData({target:{name: 'ApprovedDate', value:null}})
		}
	}, [is_approved])

	function handleCloseMore() {
		setOpenMore(false)
	}

	/**
	 * Generate a new auth key for the client. Does not save until save button is hit.
	 *
	 * Updates auth key field as well.
	 *
	 * @return {void}
	 */
	const generateNewAuthKey = async () => {
		// call the api to generate a new auth key
		let authKey = await ClientService.generateAuthKey()

		// if authKey null, warn and don't set
		if( !authKey ) {
			tipContext.show('Failed to generate new auth key')
			return
		}

		// update the form data
		setFormData({
			...form_data,
			AuthKey: authKey
		})
	}

	async function deleteClient() {
		try {
			await ClientService.deleteById(client.Id)
			setOpenMore(false)
			props.onSave({
				...client,
				DeletedDate: moment().format('YYYY-MM-DD HH:mm:ss')
			})
			tipContext.showSuccess('Drop successfully')
		} catch(e) {
			console.log(e)
			tipContext.show(e.response?.data.message)
		}
	}

	async function undeleteClient() {
		try {
			await ClientService.undeleteById(client.Id)
			setOpenMore(false)
			props.onSave({
				...client,
				DeletedDate: null
			})
			tipContext.showSuccess('Restore successfully')
		} catch(e) {
			console.log(e)
			tipContext.show(e.response?.data.message)
		}
	}

	function updateFormData(e) {
		console.log(e)
		setFormData({
			...form_data,
			[e.target.name]: e.target.value
		})
		if( !has_change ) {
			setHasChange(true)
		}

		console.log(form_data)
	}

	const common_input_props = {
		fullWidth: true, 
		variant: "outlined",
		size: "small",
		onChange: e => updateFormData(e),
		sx: {mt:2},
	}

	async function saveClient() {
		try {
			// ensure all required fields are filled out
			let missingFields = []
			for( let i = 0; i < requiredFields.length; i++ ) {
				if( !form_data[requiredFields[i]] || form_data[requiredFields[i]] === '' ) {
					missingFields.push(requiredFields[i])
				}
			}
			if( missingFields.length > 0 ) {
				tipContext.show(`The following fields are required: ${missingFields.join(', ')}`)
				return
			}

			console.log(form_data['FiscalYearType'])

			setSaving(true)
			const _client = await ClientService.updateById(client.Id, form_data)
			tipContext.showSuccess('Client saved')
			setEditing(false)
			setHasChange(false)
			if( props.onSave ) {
				props.onSave(_client)	
			}
		} catch(e) {
			tipContext.show(e.response?.data.message)
		}
		setSaving(false)
	}

	function handleDataProcessingException(e) {
		let _DataProcessingException = form_data.DataProcessingException
		_DataProcessingException[e.target.name] = e.target.checked
		setFormData({...form_data, DataProcessingException: _DataProcessingException})
	}

	function handleAddStatusModal() {
		setAddStatus(!add_status)
	}

	function syncClientPlan() {
		ClientService.syncClientPlanData(client.Id)
		tipContext.showSuccess('Start to sync this client data, please check later.')
	}

	return <>
		{!_.isEmpty(client) && 
			<Paper elevation={2}>
				<SectionTitle 
					label={client.Name} 
					subLabel={!client.DeletedDate ? client.ClientCode : 'DELETED'} 
					type="primary"
					rightComponent={
						<>
							{isAdmin ? 
								<>
									{!editing && 
										<IconButton onClick={e => setEditing(true)}>
											<ModeEditOutlineOutlinedIcon sx={{color: 'white'}}/>
										</IconButton>
									}
									{editing && 
										<IconButton onClick={e => setEditing(false)}>
											<KeyboardArrowUpOutlinedIcon sx={{color: 'white'}}/>
										</IconButton>
									}

									<IconButton onClick={e => {setOpenMore(true)}} ref={more_element}>
										<MoreVertOutlinedIcon sx={{color: 'white'}} />
									</IconButton>
									<Menu
										open={open_more}
										anchorEl={more_element.current}
										onClose={e => {setOpenMore(false)}}
									>
										{client.DeletedDate && 
											<MenuItem dense onClick={undeleteClient}>Undelete Client</MenuItem>
										}
										{!client.DeletedDate && 
											<MenuItem dense onClick={deleteClient}>Drop Client</MenuItem>
										}
										<MenuItem dense onClick={syncClientPlan}>
											Sync Client Plan Data
											{client.client_sync?.plandata_sync_status==1 && <CircularProgress size={12} sx={{ml:1}} />}
											{client.client_sync?.plandata_sync_status==2 && <CheckIcon sx={{fontSize:14, ml:1, color: 'success.main'}}/> }
											{client.client_sync?.plandata_sync_status==-1 && <PriorityHighIcon sx={{fontSize:14, ml:1, color: 'warning.main'}}/> }
										</MenuItem>
									</Menu>
								</>
							: null}
						</>
					}
				/>

				<Box sx={jss.tableSection}>
					{(!editing && !_.isEmpty(form_data)) && 
					<TableContainer>
						<Table>
							<TableBody>
								{client.DeletedDate && <TableRow>
									<TableCell sx={jss.tableLabel}>Dropped</TableCell>
									<TableCell sx={{color: 'error.main', fontWeight: 'bold'}}>{moment(client.DeletedDate).format('MM-DD-YYYY')}</TableCell>
								</TableRow>}
								{(client.title
								|| client.Address1
								|| client.Address2
								|| client.City
								|| client.State
								|| client.ZipCode
								|| client.Province
								|| client.Country) && 
								<TableRow>
									<TableCell sx={jss.tableLabel}>Address</TableCell>
									<TableCell>
										{client.title && <>{client.title} <br /></>}
										{client.Address1 && <>{client.Address1} <br /></>}
										{client.Address2 && <>{client.Address2} <br /></>}
										{client.City && <>{client.City} <br /></>}
										{client.State && <>{client.State} <br /></>}
										{client.ZipCode && <>{client.ZipCode} <br /></>}
										{client.Province && <>{client.Province} <br /></>}
										{client.Country && <>{client.Country} <br /></>}
									</TableCell>
								</TableRow>
								}
								{client.Email && <TableRow>
									<TableCell sx={jss.tableLabel}>Email</TableCell>
									<TableCell>{client.Email}</TableCell>
								</TableRow>}
								{client.Phone && <TableRow>
									<TableCell sx={jss.tableLabel}>Phone</TableCell>
									<TableCell>{client.Phone}</TableCell>
								</TableRow>}
								{client.Mobile && <TableRow>
									<TableCell sx={jss.tableLabel}>Mobile</TableCell>
									<TableCell>{client.Mobile}</TableCell>
								</TableRow>}
								
								{client.Website && <TableRow>
									<TableCell sx={jss.tableLabel}>Website</TableCell>
									<TableCell>{client.Website}</TableCell>
								</TableRow>}
								{client.Facebook && <TableRow>
									<TableCell sx={jss.tableLabel}>Facebook</TableCell>
									<TableCell>{client.Facebook}</TableCell>
								</TableRow>}
								{client.instagram && <TableRow>
									<TableCell sx={jss.tableLabel}>Instagram</TableCell>
									<TableCell>{client.instagram}</TableCell>
								</TableRow>}
								{client.tiktok && <TableRow>
									<TableCell sx={jss.tableLabel}>TikTok</TableCell>
									<TableCell>{client.tiktok}</TableCell>
								</TableRow>}
								{client.linkedin && <TableRow>
									<TableCell sx={jss.tableLabel}>LinkedIn</TableCell>
									<TableCell>{client.linkedin}</TableCell>
								</TableRow>}
								{client.pos && <TableRow>
									<TableCell sx={jss.tableLabel}>POS</TableCell>
									<TableCell>{client.pos.name}</TableCell>
								</TableRow>}
								{!_.isEmpty(client.pos?.auth_url) && <TableRow>
									<TableCell sx={jss.tableLabel}>POS WebAuth</TableCell>
										<TableCell><Box component="a" target="_blank"
											href={
												client.pos.auth_url.replace('clientCode', client.ClientCode)
											}
										>
											Authorize Shuttle
										</Box>
									</TableCell>
								</TableRow>}
			                    {client.FiscalYearType ? 
									<TableRow>
										<TableCell sx={jss.tableLabel}>Fiscal Year Type</TableCell>
										<TableCell>{client.FiscalYearType}</TableCell>
									</TableRow>
								: null}
			                    {client.FiscalYearDate && <TableRow>
									<TableCell sx={jss.tableLabel}>Fiscal Year End</TableCell>
									<TableCell>{client.FiscalYearDate}</TableCell>
			                    </TableRow>}
			                    {auth_context.checkPermission('client-show-advanced') &&
			                    <>
				                    {Boolean(client.HasForeignCurrency) && <TableRow>
									<TableCell sx={jss.tableLabel}>Has Foreign Currency</TableCell>
				                        <TableCell>Yes</TableCell>
				                    </TableRow>}
				                    {client.Notes && <TableRow>
				                        <TableCell sx={jss.tableLabel}>Notes</TableCell>
				                        <TableCell>{client.Notes}</TableCell>
				                     </TableRow>}
				                    {client.CompetitionNotes && <TableRow>
				                        <TableCell sx={jss.tableLabel}>Competition Notes</TableCell>
				                        <TableCell>{client.CompetitionNotes}</TableCell>
				                     </TableRow>}
				                </>
			                	}
							</TableBody>
						</Table>
					</TableContainer>
					}
					{editing && 
					<Fade timeout={500} in>
						<Box>
							<Collapse in={has_change}>
								<Alert sx={{mt:2}} severity="warning">You have unsaved changes, please hit save before continuing.</Alert>
							</Collapse>

							<Box sx={jss.editTitle}>General</Box>
							<TextField 
								{...common_input_props}
								label="Client Code" 
								name="ClientCode"
								value={form_data.ClientCode || ''}
								required
							/>
							<TextField 
								{...common_input_props}
								label="Name" 
								name="Name"
								value={form_data.Name || ''}
								required
							/>

							<Box sx={jss.editTitle}>Primary address</Box>
							<TextField 
								{...common_input_props}
								label="Address 1" 
								name="Address1"
								value={form_data.Address1 || ''}
							/>
							<TextField 
								{...common_input_props}
								label="Address 2" 
								name="Address2"
								value={form_data.Address2 || ''}
							/>
							<TextField
								{...common_input_props}
								label="City"
								name="City"
								value={form_data.City || ""}
							/>
							<TextField
								{...common_input_props}
								label="State"
								name="State"
								value={form_data.State || ""}
							/>
							<TextField
								{...common_input_props}
								label="ZipCode"
								name="ZipCode"
								value={form_data.ZipCode || ""}
							/>
							<TextField
								{...common_input_props}
								label="Province"
								name="Province"
								value={form_data.Province || ""}
							/>

							<Box sx={jss.editTitle}>Contact details</Box>
							<TextField
								{...common_input_props}
								label="Legal Name"
								name="LegalName"
								value={form_data.LegalName || ""}
							/>
							<TextField
								{...common_input_props}
								label="Email"
								name="Email"
								value={form_data.Email || ""}
							/>
							<TextField
								{...common_input_props}
								label="Phone"
								name="Phone"
								value={form_data.Phone || ""}
							/>
							<TextField
								{...common_input_props}
								label="Mobile"
								name="Mobile"
								value={form_data.Mobile || ""}
							/>
							<TextField
								{...common_input_props}
								label="Website"
								name="Website"
								value={form_data.Website || ""}
							/>
							<TextField
								{...common_input_props}
								label="Twitter"
								name="Twitter"
								value={form_data.Twitter || ""}
							/>
							<TextField
								{...common_input_props}
								label="Facebook"
								name="Facebook"
								value={form_data.Facebook || ""}
							/>
							<TextField
								{...common_input_props}
								label="Instagram"
								name="instagram"
								value={form_data.instagram || ""}
							/>
							<TextField
								{...common_input_props}
								label="TikTok"
								name="tiktok"
								value={form_data.tiktok || ""}
							/>
							<TextField
								{...common_input_props}
								label="LinkedIn"
								name="linkedin"
								value={form_data.linkedin || ""}
							/>

							{
							// <Box sx={jss.editTitle}>Orion</Box>
							// <Box sx={jss.textField}>ClientId: 514</Box>
							// <Box sx={jss.textField}>Client Code: DH109</Box>
							// <Box sx={jss.textField}>Set to Orion Planning: true</Box>
							// <Box sx={jss.textField}>Re-process Actuals: false</Box>
							// <Box sx={jss.textField}>Re-process Last12Months: false</Box>
							// <Box sx={jss.textField}>Priority Sequence: 1</Box>
							// <Box sx={jss.textField}>Last ClientData Sync: 2022-04-12T18:15:41</Box>
							}

							<Box sx={jss.editTitle}>Technical</Box>
							<TextField
								{...common_input_props}
								label="Associate Marketer"
								name="AssociateMarketerId"
								value={form_data.AssociateMarketerId || ""}
								select
							>
							{
								associateMarketerList.map(listItem => 
									<MenuItem key={`associate_marketer_option_${listItem.id}`} value={listItem.id}>
										{listItem.name}
									</MenuItem>
								)
							}
							</TextField>
							<TextField
								{...common_input_props}
								label="POS System"
								name="PointOfSaleId"
								value={form_data.PointOfSaleId || ""}
								select
							>
							{
								posSystemList.map(listItem => 
									<MenuItem key={`pos_option_${listItem.id}`} value={listItem.id}>
										{listItem.name}
									</MenuItem>
								)
							}
							</TextField>
							<TextField
								{...common_input_props}
								label="Fiscal Year Type"
								name="FiscalYearType"
								value={form_data.FiscalYearType}
								required
								select
							>
								<MenuItem value='Calendar'>Calendar</MenuItem>
								<MenuItem value='4-5-4'>4-5-4</MenuItem>
								<MenuItem value='4-4-5'>4-4-5</MenuItem>
							</TextField>
							<LocalizationProvider dateAdapter={AdapterMoment}>
								<DesktopDatePicker
									label="Fiscal Year End"
									value={form_data.FiscalYearDate}
									inputFormat="MMM DD"
									mask=""
									onChange={e => updateFormData({
										target: {
											value: e,
											name: 'FiscalYearDate'
										}
									})}
									renderInput={(params) => <TextField {...params}
										fullWidth={true}
										variant="outlined"
										size="small"
										sx={{mt:2}} 
									/>}
								/>
							</LocalizationProvider>
							<TextField
								{...common_input_props}
								label="Imu"
								name="Imu"
								value={form_data.Imu || ""}
								type="number"
							/>
							<FormGroup>
								<FormControlLabel
									control={
										<Switch checked={!!form_data.HasForeignCurrency} onChange={e => updateFormData({target:{name: 'HasForeignCurrency', value:e.target.checked}})} />
									}
									label="Has Foreign Currency?"
								/>
							</FormGroup>
							<LocalizationProvider dateAdapter={AdapterMoment}>
								<DesktopDatePicker
									label="Year Founded"
									value={form_data.CompanyFounded}
									mask=""
									inputFormat="yyyy-MM-DD"
									onChange={e => updateFormData({
										target: {
											value: e,
											name: 'CompanyFounded'
										}
									})}
									renderInput={(params) => <TextField {...params}
										fullWidth={true}
										variant="outlined"
										size="small"
										sx={{mt:2}} 
									/>}
								/>
							</LocalizationProvider>
							<TextField
								{...common_input_props}
								label="RO Auth Key"
								name="AuthKey"
								value={form_data.AuthKey || ""}
							/>
							{/* Button to generate new auth key */}
							<Button variant="outlined" sx={{mt:1}} onClick={generateNewAuthKey}>Generate New Auth Key</Button>

							<Box sx={jss.editTitle}>Acquisition details</Box>
							<TextField
								{...common_input_props}
								label="Referred By"
								name="ReferredBy"
								value={form_data.ReferredBy || ""}
								select
							>
								<MenuItem value="AETA">AETA</MenuItem>
                                <MenuItem value="DMS">DMS</MenuItem>
                                <MenuItem value="WhizBang">WhizBang</MenuItem>
                                <MenuItem value="Client Referral">Client Referral</MenuItem>
                                <MenuItem value="Cold Calls">Cold Calls</MenuItem>
                                <MenuItem value="Industry or business meeting">Industry or business meeting</MenuItem>
                                <MenuItem value="NWB">NWB</MenuItem>
                                <MenuItem value="Management One® recruiting and selling">Management One® recruiting and selling</MenuItem>
                                <MenuItem value="Networking">Networking</MenuItem>
                                <MenuItem value="Retail Pro">Retail Pro</MenuItem>
                                <MenuItem value="RICS">RICS</MenuItem>
                                <MenuItem value="RMSA">RMSA</MenuItem>
                                <MenuItem value="RunIt">RunIt</MenuItem>
                                <MenuItem value="SnapRetail">SnapRetail</MenuItem>
                                <MenuItem value="Website">Website</MenuItem>
                                <MenuItem value="Other">Other</MenuItem>
							</TextField>

							<Box sx={jss.editTitle}>Notes</Box>
							<TextField
								{...common_input_props}
								label="General Notes"
								name="Notes"
								value={form_data.Notes || ""}
								multiline
								rows={4}
							/>
							<TextField
								{...common_input_props}
								label="Competition Notes"
								name="CompetitionNotes"
								value={form_data.CompetitionNotes || ""}
								multiline
								rows={4}
							/>

							<Box sx={jss.editTitle}>Commercial</Box>
							<LocalizationProvider dateAdapter={AdapterMoment}>
								<DesktopDatePicker
									label="Active Date"
									value={form_data.ActiveDate ?? ""}
									inputFormat="yyyy-MM-DD"
									timezone="UTC"
									mask=""
									onChange={e => updateFormData({
										target: {
											value: e,
											name: 'ActiveDate'
										}
									})}
									renderInput={(params) => <TextField {...params}
																		fullWidth={true}
																		variant="outlined"
																		size="small"
																		sx={{mt:2}}
									/>}
								/>
								<DesktopDatePicker
									label="First Plan Publish Date"
									value={form_data.BillingStartDate ? moment(form_data.BillingStartDate).utcOffset(0, false).format('YYYY-MM-DD') : ''}
									inputFormat="yyyy-MM-DD"
									mask=""
									onChange={e => updateFormData({
										target: {
											value: e,
											name: 'BillingStartDate'
										}
									})}
									renderInput={(params) => <TextField {...params}
										fullWidth={true}
										variant="outlined"
										size="small"
										sx={{mt:2}} 
									/>}
								/>
							</LocalizationProvider>

							<Box sx={jss.editTitle}>Integrations</Box>
							<FormControlLabel
								control={
									<Switch checked={!!form_data.HasShuttle} onChange={e => updateFormData({target:{name: 'HasShuttle', value:e.target.checked}})} />
								}
								label="Shuttle Integration "
							/>
							<Collapse in={Boolean(form_data?.HasShuttle)}>
								<TextField
									{...common_input_props}
									label="Shuttle Rate"
									name="ShuttleRate"
									value={form_data.ShuttleRate || 0}
									type="number"
								/>
							</Collapse>
							<FormControlLabel
								control={
									<Switch checked={!!form_data.HasHeadCount} onChange={e => updateFormData({target:{name: 'HasHeadCount', value:e.target.checked}})} />
								}
								label="Headcount integration "
							/>
							<Collapse in={Boolean(form_data?.HasHeadCount)}>
								<TextField
									{...common_input_props}
									label="HeadCount Rate"
									name="headCount_rate"
									value={form_data.headCount_rate || 0}
									type="number"
								/>
							</Collapse>

							<Box sx={jss.editTitle}>Admin</Box>
							<FormGroup>
								<FormControlLabel
									control={
										<Switch checked={!!form_data.IsManualEntry} onChange={e => updateFormData({target:{name: 'IsManualEntry', value:e.target.checked}})} />
									}
									label="Is Manual Entry?"
								/>
								<FormControlLabel
									control={
										<Switch checked={!!form_data.IsTest} onChange={e => updateFormData({target:{name: 'IsTest', value:e.target.checked}})} />
									}
									label="Is Test?"
								/>
								<FormControlLabel
									control={
										<Switch checked={!!is_approved} onChange={e => setIsApproved(!is_approved)} />
									}
									label="Is Management One Approved? "
								/>
								<FormControlLabel
									control={
										<Switch checked={!!form_data.IsBeta} onChange={e => updateFormData({target:{name: 'IsBeta', value:e.target.checked}})} />
									}
									label="Legacy Excel Export?"
								/>
							</FormGroup>

							<Box sx={jss.editTitle}>POS API Settings</Box>
							<TextField
								{...common_input_props}
								label="POS API Key"
								name="ApiKey"
								value={form_data.ApiKey}
							/>
							<TextField
								{...common_input_props}
								label="POS API URL"
								name="ApiUrl"
								value={form_data.ApiUrl}
							/>
							<TextField
								{...common_input_props}
								label="POS API Field"
								name="ApiField"
								value={form_data.ApiField}
							/>

							<Box sx={jss.editTitle}>Processing Rules</Box>
							<Box sx={{mt:2, color:'text.secondary'}}>Current Status: {client.current_status ? ClientService.getStatusNameByStatusId(client.current_status.status_id) : 'Fully Operational'}</Box>
							<FormGroup>
								<FormControlLabel
									control={
										<Switch 
											value={form_data.DataProcessingException?.DoAutomaticFinalizations} 
											checked={form_data.DataProcessingException?.DoAutomaticFinalizations} 
											onChange={handleDataProcessingException} 
											name="DoAutomaticFinalizations" 
										/>
									}
									label="Finalize automatically?"
								/>
								<FormControlLabel
									control={
										<Switch 
											value={form_data.DataProcessingException?.DoNegativeAdjustments} 
											checked={form_data.DataProcessingException?.DoNegativeAdjustments} 
											onChange={handleDataProcessingException} 
											name="DoNegativeAdjustments" 
										/>
									}
									label=" Do negative adjustments? "
								/>
								<FormControlLabel
									control={
										<Switch 
											value={form_data.DataProcessingException?.HasMarkdownInventoryAdjustments} 
											checked={form_data.DataProcessingException?.HasMarkdownInventoryAdjustments} 
											onChange={handleDataProcessingException} 
											name="HasMarkdownInventoryAdjustments" 
										/>
									}
									label=" Has Markdown Inventory Adjustments? "
								/>
								<FormControlLabel
									control={
										<Switch 
											value={form_data.DataProcessingException?.DoPerpetuateInventory} 
											checked={form_data.DataProcessingException?.DoPerpetuateInventory} 
											onChange={handleDataProcessingException} 
											name="DoPerpetuateInventory" 
										/>
									}
									label=" Perpetuate Inventory? "
								/>
								<FormControlLabel
									control={
										<Switch 
											value={form_data.DataProcessingException?.PeriodFromTransactionDate} 
											checked={form_data.DataProcessingException?.PeriodFromTransactionDate} 
											onChange={handleDataProcessingException} 
											name="PeriodFromTransactionDate" 
										/>
									}
									label=" SkuData Period from Transaction Date? "
								/>
							</FormGroup>

							<Box sx={jss.editTitle}>Status</Box>
							<Button variant="outlined" sx={{mt:1}} onClick={handleAddStatusModal}>Add Status</Button>
							<AddStatusModal 
								open={add_status} 
								client={client}
								onClose={handleAddStatusModal}
								current_status={client.current_status ? client.current_status.status_id : 4}
							/>

							<Box sx={{mt:2}}>
								<LoadingButton loading={saving} size="large" variant="contained" onClick={saveClient} startIcon={<SaveTwoToneIcon />}>
									Save Client
								</LoadingButton>
							</Box>

						</Box>
					</Fade>
					}
				</Box>
			</Paper>
		}
		{_.isEmpty(client) && 
			<Paper elevation={2}>
				<SectionTitle 
				 	label="Loading"
					type="primary"
					rightComponent={
						<IconButton>
							<MoreVertOutlinedIcon sx={{color: 'white'}}/>
						</IconButton>
					}
				/>
				<Box sx={jss.tableSection}>
					<Skeleton sx={{mt: 1.5}} />
					<Skeleton sx={{mt: 1.5}} />
					<Skeleton sx={{mt: 1.5}} />
					<Skeleton sx={{mt: 1.5}} />
					<Skeleton sx={{mt: 1.5}} />
				</Box>
			</Paper>
		}
	</>
}

export default ClientProfile