import React, { useEffect, useState } from 'react';
import {
	Card,
	CardContent,
	CardHeader,
	Table, TableBody, TableCell,
	TableContainer,
	TableRow,
	Paper,
	IconButton,
} from "@mui/material";
import CachedIcon from '@mui/icons-material/Cached';
import AuthProvider from "../../providers/AuthProvider";

const cssInJs = {
  header: {
	backgroundColor: "#fff",
  },
  grayBlock: {
	background: '#fafafa',
	padding: '10px',
  },
  textInput: {
	marginRight: '20px',
	marginBottom: '15px'
  },
  formControl: {
	minWidth: 80,
	marginRight: '20px',
  },
  flexContainer: {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
  },
}
function JobLog({ selectedFlightJobLog, onRefresh }) {
	const auth = AuthProvider.useAuth()
	const [isDev, setIsDev] = useState(false)

	// is admin
	useEffect(() => {
		// role-create marks admin, but we don't have role specific checks currently
		if (auth.checkPermission('dev-tools')) {
			setIsDev(true)
		}
	}, [auth])

	return (
		<Card>
			<div style={{ position: 'sticky', top: 0, zIndex: 1 }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
					<CardHeader
						title={'Job Log'}
						sx={{
							...cssInJs.header,
							'&.MuiTypography-root': {
								fontSize: '16px',
							},
						}}
					/>
                    <IconButton 
						onClick={() => onRefresh(selectedFlightJobLog)}
					>
						<CachedIcon />
					</IconButton>
                </div>
            </div>
			<CardContent component={Paper}>
				<TableContainer>
				<Table className="material-table">
					<TableBody>
					<TableRow>
						<TableCell>Status</TableCell>
						<TableCell>
							{selectedFlightJobLog.job_json ?? ''}
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>Result</TableCell>
						<TableCell>{selectedFlightJobLog.result ?? ''}</TableCell>
					</TableRow>
					{isDev ? 
						<TableRow>
							<TableCell>Dev Error</TableCell>
							<TableCell>{selectedFlightJobLog?.dev_error || ''}</TableCell>
						</TableRow>
					: null}
					<TableRow>
						<TableCell>Job</TableCell>
						<TableCell>{selectedFlightJobLog?.job_type_id || 'N/A'}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>Period</TableCell>
						<TableCell>{selectedFlightJobLog?.period || 'N/A'}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>Duration</TableCell>
						<TableCell>{selectedFlightJobLog?.duration || '0'}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>Total Duration</TableCell>
						<TableCell>{selectedFlightJobLog?.totalDuration || '0'}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>Queue</TableCell>
						<TableCell>{selectedFlightJobLog?.queue || '0'}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>Start</TableCell>
						<TableCell>{selectedFlightJobLog?.start_date || 'N/A'}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>End</TableCell>
						<TableCell>{selectedFlightJobLog?.end || 'N/A'}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>Id</TableCell>
						<TableCell>{selectedFlightJobLog?.id || 'N/A'}</TableCell>
					</TableRow>
					</TableBody>
				</Table>
				</TableContainer>
			</CardContent>
		</Card>
	);
}

export default JobLog;