import React, { useEffect, useState, useRef } from 'react';
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    FormControlLabel, Table, TableBody, TableCell,
    TableContainer,
    TableHead, TableRow,
    TextField,
    IconButton,
    Typography,
    Menu,
    MenuItem,
    Button,
} from "@mui/material";
import { DatePicker, DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment/moment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import FlightService from '../../services/FlightService';
import CachedIcon from '@mui/icons-material/Cached';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';


const cssInJs = {
    header: {
        backgroundColor: "#fff",
    },
    grayBlock: {
        background: '#fafafa',
        padding: '10px',
    },
    textInput: {
        marginRight: '20px',
        marginBottom: '15px',
        marginTop: '15px'
    },
    formControl: {
        minWidth: 80,
        marginRight: '20px',
    },
    flexContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
}

function FlightControlLogs({ onJobLogClick, onLoadedJobLogData, flightLogs, selectedClient, selectedJobLog, handleReloadData, tipContext }) {
    const [loading, setLoading] = useState(false);
    const [filter, setFilter] = useState({
        startDate: null,
        endDate: null,
        showErrorsOnly: false,
    });
    const [filteredLogs, setFilteredLogs] = useState(flightLogs);
    const [open_more, setOpenMore] = useState(false)
    const more_element = useRef()

    useEffect(() => {
        let _filteredLogs = flightLogs;

        if (filter.showErrorsOnly) {
            _filteredLogs = _filteredLogs.filter((log) => log.result);
        }

        if (selectedClient) {
            _filteredLogs = _filteredLogs.filter((log) => log.client?.Id === selectedClient.Id);
        }

        if (filter.startDate && !filter.endDate) {
            _filteredLogs = _filteredLogs.filter((log) => moment(log.start_date).isSameOrAfter(filter.startDate, 'day'));
        } else if (!filter.startDate && filter.endDate) {
            _filteredLogs = _filteredLogs.filter((log) => moment(log.start_date).isSameOrBefore(filter.endDate, 'day'));
        } else if (filter.startDate && filter.endDate) {
            _filteredLogs = _filteredLogs.filter((log) => moment(log.start_date).isBetween(filter.startDate, filter.endDate, 'day'));
        }

        setFilteredLogs(_filteredLogs);
    }, [filter, onLoadedJobLogData, selectedClient]);

    const handleTableClick = (job) => {
        onJobLogClick(job);
    };

    const handleCheckboxChange = () => {
        setFilter((prevFilter) => ({
            ...prevFilter,
            showErrorsOnly: !prevFilter.showErrorsOnly,
        }));
    };

    const rerunFailedLightspeedJobs = async () => {
        setOpenMore(false);
        FlightService.reRunFailedLSDailyJobs().then(() => {
            handleReloadData();
            tipContext.showSuccess('Re-run failed LS Daily Jobs successfully');
        }).catch((error) => {
            tipContext.show('Failed to re-run LS Daily Jobs');
        })
    }

    return (
        <Card>
            <div style={{ position: 'sticky', top: 0, zIndex: 1 }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <CardHeader
                        title={'Flight Control Logs'}
                        sx={{
                            ...cssInJs.header,
                            '& .MuiTypography-root': {
                                fontSize: '16px',
                            },
                            flexGrow: 1,
                        }}
                    />
                    <IconButton onClick={e => { setOpenMore(true) }} ref={more_element}>
                        <MoreVertOutlinedIcon sx={{ color: 'black' }} />
                    </IconButton>
                    <Menu
                        open={open_more}
                        anchorEl={more_element.current}
                        onClose={e => { setOpenMore(false) }}
                    >
                        <MenuItem dense onClick={handleReloadData}>Reload Logs</MenuItem>
                        <MenuItem dense onClick={rerunFailedLightspeedJobs}>Re-run failed LS Daily Jobs</MenuItem>
                    </Menu>
                </div>
                <Box sx={cssInJs.grayBlock}>
                    <div style={cssInJs.flexContainer}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                label="Start date"
                                value={filter.startDate}
                                inputFormat="MMM dd"
                                mask=""
                                onChange={(e) => setFilter((prevFilter) => ({ ...prevFilter, startDate: moment(e) }))}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth={true}
                                        variant="outlined"
                                        size="small"
                                        sx={cssInJs.textInput}
                                    />
                                )}
                            />
                        </LocalizationProvider>

                        <LocalizationProvider dateAdapter={AdapterDateFns} sx={{ m: 1 }}>
                            <DesktopDatePicker
                                label="End date"
                                value={filter.endDate}
                                inputFormat="MMM dd"
                                mask=""
                                onChange={(e) => setFilter((prevFilter) => ({ ...prevFilter, endDate: moment(e) }))}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth={true}
                                        variant="outlined"
                                        size="small"
                                        sx={cssInJs.textInput}
                                    />
                                )}
                            />
                        </LocalizationProvider>

                        {/* <Button variant="contained" color="primary" onClick={dateSearchManagers.searchDates} sx={{ m: 1 }}>Go</Button> */}

                        {/* Checkbox Component */}
                        <FormControlLabel
                            control={<Checkbox checked={filter.showErrorsOnly}
                                onChange={handleCheckboxChange}
                                color="primary" />}
                            label="Error"
                        />
                    </div>
                </Box>
            </div>
            <CardContent>
                {loading ? (
                    <p>Loading...</p>
                ) : (
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Code</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Period</TableCell>
                                    <TableCell>Start</TableCell>
                                    <TableCell>End</TableCell>
                                    <TableCell>Result</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredLogs?.length > 0 ? (
                                    filteredLogs.map((job) => (
                                        <TableRow 
                                            key={job.id}
                                            onClick={() => handleTableClick(job)}
                                            sx={{ cursor: 'pointer', backgroundColor: job.id == selectedJobLog?.id ? '#f0f0f0' : 'inherit'}}
                                        >
                                            <TableCell>{job.client?.ClientCode}</TableCell>
                                            <TableCell>{job.client?.Name}</TableCell>
                                            <TableCell>{job.period}</TableCell>
                                            <TableCell>{job.start_date}</TableCell>
                                            <TableCell>{job.end_date}</TableCell>
                                            <TableCell style={{ color: job.result ? 'red' : 'black', fontWeight: job.result ? 'bold' : 'normal' }}>{job.result ? 'Failed' : (job.end_date ? 'Passed' : 'Running')}</TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell align="left" colSpan={6}>
                                            No available data
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </CardContent>
        </Card>
    );
}

export default FlightControlLogs