import React, { useState } from "react";
import { Box, Grid, Paper } from "@mui/material";
import jss from '../../pages/flight/FlightCommonJss'
import FlightControlJobs from '../../pages/flight/FlightControlJobs';
import FlightControlLogs from '../../pages/flight/FlightControlLogs';
import JobSettings from '../../pages/flight/JobSettings';
import FlightService from "../../services/FlightService";
import JobLog from "./JobLog";
import FlightClients from "./FlightClients";
import TipContext from "../../providers/GlobalTip";
import moment from "moment";

const cssInJs = {
	gridItem: {
		maxHeight: '1000px',
		overflowY: 'auto',
	},
}

function FlightControl(props) {
	const tip_context = TipContext.useMessage()

	// Clients
	const [clients, setClients] = useState([]);
	const [selectedClient, setSelectedClient] = React.useState(null);

	// Job Settings
	const [flightJobSettings, setFlightJobSettings] = useState([]);
	const [selectedFlightJob, setSelectedFlightJob] = React.useState(null);
	const [jobSettingsLoading, setJobSettingsLoading] = React.useState(false);

	// Flight Logs
	const [allRecentFlightLogs, setAllRecentFlightLogs] = useState([]);
	const [searchedFlightLogs, setSearchedFlightLogs] = useState([]);
	const [selectedFlightJobLog, setSelectedFlightJobLog] = React.useState(null);
	const [jobLogLoading, setJobLoading] = useState(false);

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const _clients = await FlightService.getFlightClients();
				setClients(_clients);

				const _recentFlightLogs = await FlightService.getRecentFlightJobLogs();
				setAllRecentFlightLogs(_recentFlightLogs);
				setSearchedFlightLogs(_recentFlightLogs);
				// console.log('Recent flight logs:', _recentFlightLogs);
			} catch (error) {
				console.error('Error', error);
			}
		};
		fetchData();
	}, []);

	React.useEffect(() => {
		setSelectedFlightJob(null);
		// if flight job settings not present, fetch for the client
		if (selectedClient) {
			setJobSettingsLoading(true);

			// get flight logs for client
			const fetchFlightLogs = async () => {
				try {
					const _flightLogs = await FlightService.getFlightJobLogsForClient(selectedClient.Id);
					setSearchedFlightLogs(_flightLogs);
				} catch (error) {
					console.error('Error', error);
				}
			};
			fetchFlightLogs();

			// if selectedClient is in flightJobSettings, skip, otherwise load
			const clientSettings = flightJobSettings.find((setting) => setting.client_id === selectedClient.Id);
			if (!clientSettings) {
				const fetchData = async () => {
					try {
						const _flightJobSettings = await FlightService.getFlightJobSettingsForClient(selectedClient.Id);

						if (_flightJobSettings != null) {
							setFlightJobSettings([...flightJobSettings, _flightJobSettings]);
						}

						setSelectedFlightJob(_flightJobSettings);
					} catch (error) {
						console.error('Error', error);
					}

					setJobSettingsLoading(false);
				};
				fetchData();
			} else {
				setSelectedFlightJob(clientSettings);
				setJobSettingsLoading(false);
			}
		} else {
			setSearchedFlightLogs(allRecentFlightLogs);
			setJobSettingsLoading(false);
		}
	}, [selectedClient]);

	const handleJobClick = (selectedFlightJob) => {
		setSelectedFlightJob(selectedFlightJob);
	};

	const handleRefreshJobLog = async (jobLog) => {
		if (!jobLog || !jobLog.id) {
			return;
		}

		FlightService.getFlightJobLogById({ flightJobLogId: jobLog.id, relationsToLoad: ['client']}).then((response) => {
			const _jobLog = response;
			setSelectedFlightJobLog(_jobLog);

			// replace in logs list
			const _logs = searchedFlightLogs.map((log) => {
				if (log.id === jobLog.id) {
					return _jobLog;
				}
				return log;
			});
			setSearchedFlightLogs(_logs);

			tip_context.showSuccess('Job log refreshed');
		}).catch((error) => {
			console.error('Error refreshing job log', error);
			tip_context.show('Error refreshing job log');
		});
	}

	const handleJobLogClick = (selectedFlightJobLog) => {
		setSelectedFlightJobLog(selectedFlightJobLog);
	}

	const handleDataLoaded = (loadedData) => {
		setLoading(false);
	};

	const handleJobLogLoaded = (loadedJobLogs) => {
		setJobLoading(false);
	}

	const handleAddJob = () => {
		setShowJobDetails((prev) => !prev);
	};

	const handleSaveFlightJob = async (flightJob) => {
		try {
			const response = await FlightService.updateFlightJob(flightJob.id, flightJob);
			
			tip_context.showSuccess('Job settings saved');

			// update flight job settings
			const updatedSettings = flightJobSettings.map((setting) => {
				if (setting.id === flightJob.id) {
					return flightJob;
				}
				return setting;
			});

			setFlightJobSettings(updatedSettings);

			// update selected flight job
			setSelectedFlightJob(flightJob);
		} catch (error) {
			console.log(error)
			tip_context.show('Error saving flight job');
		}
	}

	const handleToggleJob = async (enabled) => {
		try {
			// if client is selected and selectedFlightJob is null, undefined, or '' then create, otherwise update
			if (selectedClient && (!selectedFlightJob || selectedFlightJob === null || selectedFlightJob === '')) {
				await FlightService.createFlightJob({
					client_id: selectedClient.Id,
					enabled: enabled,
					job_type_id: 2,
					time_of_day: 0,
					interval: 202402
				}).then((response) => {
					console.log('Created job', response);
					setSelectedFlightJob(response);
					setFlightJobSettings([...flightJobSettings, response]);
				});
			} else {
				await FlightService.updateFlightJob(selectedFlightJob.id, {
					...selectedFlightJob,
					enabled: enabled,
				}).then((response) => {
					setSelectedFlightJob(response);
				});
			}

			tip_context.showSuccess('Job toggled');
		} catch (error) {
			console.log('Error toggling job', error);
			tip_context.show('Error toggling job');
		}
	}

	const handleRun = async ({ period = null, sinceLastRun = false, loadFullInventory = false, skipInventory = false, onOrderOnly = false }) => {
		if (!selectedClient || !selectedFlightJob) {
			return;
		}

		const _currentClientId = selectedClient.Id;

		try {
			// if not period, default to today formatted as yyyymm
			const _period = period ? moment(period).format('YYYYMM') : moment().format('YYYYMM');

			FlightService.runFlightJob(selectedFlightJob.id, {
				period: _period,
				sinceLastRun: sinceLastRun,
				loadFullInventory: loadFullInventory,
				skipInventory: skipInventory,
				onOrderOnly: onOrderOnly
			}).then((response) => {
				// if selectedClient id is still the same, append to logs
				// if (selectedClient.Id === _currentClientId) {
				// 	setSearchedFlightLogs([response, ...searchedFlightLogs]);
				// 	tip_context.showSuccess('Job completed and appended to log list');
				// }
			}).catch((error) => {
				console.error('Error running job', error);
				tip_context.show('Error running job');
			});

			tip_context.showSuccess('Job started');

			// get latest logs
			handleReloadLogs(true);
		} catch (error) {
			console.error('Error running job', error);
			tip_context.show('Error running job');
		}
	}

	/**
	 * Reloads the flight logs.
	 * 
	 * @param selectLatest {boolean} - If true, will select the latest log
	 */
	const handleReloadLogs = async (selectLatest = false) => {
		try {
			const _recentFlightLogs = await FlightService.getRecentFlightJobLogs();
			setAllRecentFlightLogs(_recentFlightLogs);
			
			// load for selected client if client selected
			if (selectedClient) {
				const _flightLogs = await FlightService.getFlightJobLogsForClient(selectedClient.Id);
				setSearchedFlightLogs(_flightLogs);

				if (selectLatest) {
					setSelectedFlightJobLog(_flightLogs[0]);
				}
			} else {
				setSearchedFlightLogs(_recentFlightLogs);

				if (selectLatest) {
					setSelectedFlightJobLog(_recentFlightLogs[0]);
				}
			}
		} catch (error) {
			console.error('Error', error);
		}
	}

	return (
		<Box sx={{ py: 2, minWidth: 1100 }}>
			<Grid container spacing={2}>
				{/* Title */}
				<Grid item xs={12}>
					<Box sx={jss.title}>Flight Control Page</Box>
					{/* <Paper elevation={2} sx={{ mb: 2 }}>
						<Box>
							This page is a still a work in progress, so it may be missing some features. Known missing features include: searching logs by date (more than 7 days old) when a client isn't selected. <strong>NOTE that enable/disable jobs is not currently functional for non Lightspeed Clients</strong>.
						</Box>
					</Paper> */}
				</Grid>

				{/* FlightControlJobs */}
				<Grid item xs={12} sm={4} sx={cssInJs.gridItem}>
					<FlightClients
						clients={clients}
						setSelectedClient={setSelectedClient}
						selectedClient={selectedClient}
						loading={jobSettingsLoading || jobLogLoading}
					/>
				</Grid>

				{/* FlightControlLogs */}
				<Grid item xs={12} sm={4} sx={cssInJs.gridItem}>
					<FlightControlLogs
						onJobLogClick={handleJobLogClick}
						onLoadedJobLogData={handleJobLogLoaded}
						flightLogs={searchedFlightLogs}
						selectedClient={selectedClient}
						selectedJobLog={selectedFlightJobLog}
						handleReloadData={handleReloadLogs}
						tipContext={tip_context}
					/>
				</Grid>

				{/* FlightControlLogs and JobSettings */}
				<Grid item xs={12} sm={4}>
					{/* JobSettings */}
					{(selectedClient && !jobSettingsLoading) ?
						<JobSettings
							jobSettings={selectedFlightJob}
							onSave={handleSaveFlightJob}
							toggleJob={handleToggleJob}
							onRun={handleRun}
							tipContext={tip_context}
							client={selectedClient}
							posId={selectedClient.PointOfSaleId}
						/>
					: null}

					{/* JobLog */}
					{selectedFlightJobLog && selectedFlightJobLog !== null && !jobLogLoading && (
						<JobLog
							selectedFlightJobLog={selectedFlightJobLog}
							onRefresh={handleRefreshJobLog}
						/>
					)}
				</Grid>
			</Grid>
		</Box>
	)
}

export default FlightControl