import { Box, Button, CardHeader, FormControlLabel, IconButton, Paper, Stack, TextField } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import moment from 'moment';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Checkbox } from "@mui/material";
import { MoreVertOutlined } from "@mui/icons-material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FlightService from "../../services/FlightService";

const timezoneOptions = [
	'America/New_York',
	'America/Chicago',
	'America/Denver',
	'America/Los_Angeles',
	'America/Anchorage',
	'Pacific/Honolulu',
];

const JobSettings = ({ jobSettings, onSave, onRun, tipContext, client, toggleJob, posId }) => {
	const [openMore, setOpenMore] = useState(false);
	const moreElement = useRef(null);

	const [runDate, setRunDate] = useState(moment());
	const [sinceLastRun, setSinceLastRun] = useState(false);
	const [disabledSinceLastRun, setDisabledSinceLastRun] = useState(false);
	const [loadFullInventory, setLoadFullInventory] = useState(false);
	const [skipInventory, setSkipInventory] = useState(false);
	const [onOrderOnly, setOnOrderOnly] = useState(false);

	const [jobData, setJobData] = useState(jobSettings);

	useEffect(() => {
		setJobData(jobSettings);
	}, [jobSettings]);

	useEffect(() => {
		if (!(runDate.month() == moment().month() && runDate.year() == moment().year())) {
			setSinceLastRun(false);
			setDisabledSinceLastRun(true);
		} else {
			setDisabledSinceLastRun(false);
		}
	}, [runDate]);

	const syncFromStagingToActuals = async () => {
		setOpenMore(false);
		tipContext.showSuccess('Syncing from Staging to Actuals. This can take 30-60 seconds.');
		FlightService.syncFromStagingToActuals(client.Id).then((response) => {
			tipContext.showSuccess('Synced from Staging to Actuals');
		}).catch((error) => {
			console.log(error);
			tipContext.show('Error syncing from Staging to Actuals');
		});
	}

	return (
		<Paper elevation={2} sx={{mb:2}}>
			<Box sx={{mb:2}} spacing={2}>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<CardHeader
						title={'Flight Control Logs for ' + (client.ClientCode ?? '')}
						sx={{
							backgroundColor: 'white',
							'& .MuiTypography-root': {
									fontSize: '16px',
							},
							flexGrow: 1,
							borderBottom: '1px solid #ccc',
							fontWeight: 'bold',
						}}
					/>
					{(jobSettings != null && jobSettings != 'undefined' && jobSettings != '') ? (
						<>
							<IconButton onClick={e => { setOpenMore(true) }} ref={moreElement}>
								<MoreVertOutlined sx={{
									color: 'black', 
								}} />
							</IconButton>
							<Menu
								open={openMore}
								anchorEl={moreElement.current}
								onClose={e => { setOpenMore(false) }}
							>
								<MenuItem dense onClick={() => syncFromStagingToActuals()}>Sync from Staging</MenuItem>
							</Menu>
						</>
					) : null}
				</div>
				{jobSettings ?
					<>
						<Box sx={{ mx: 2, mb: 2 }}>
							<Stack sx={{mb: 2}}>
								{posId == 1113 ?
									<FormControlLabel
										sx={{ ml: 1 }}
										control={
											<Checkbox
												checked={sinceLastRun}
												onChange={(e) => setSinceLastRun(e.target.checked)}
												color="primary"
												disabled={disabledSinceLastRun}
											/>
										}
										label="Data since last run only?"
									/>
								: null}
								{posId == 1113 ?
									<FormControlLabel
										sx={{ ml: 1 }}
										control={
											<Checkbox
												checked={loadFullInventory}
												onChange={(e) => setLoadFullInventory(e.target.checked)}
												color="primary"
											/>
										}
										label="Load full inventory?"
									/>
								: null}
								{posId == 1113 ?
									<FormControlLabel
										sx={{ ml: 1 }}
										control={
											<Checkbox
												checked={skipInventory}
												onChange={(e) => setSkipInventory(e.target.checked)}
												color="primary"
											/>
										}
										label="Skip Inventory?"
									/>
								: null}
								{posId = 1113 ? 
									<FormControlLabel
										sx={{ ml: 1 }}
										control={
											<Checkbox
												checked={onOrderOnly}
												onChange={(e) => setOnOrderOnly(e.target.checked)}
												color="primary"
											/>
										}
										label="Grab only On Order?"
									/>
								: null}
							</Stack>
							<LocalizationProvider dateAdapter={AdapterMoment}>
								<DatePicker
									views={['year', 'month']}
									label="Run Date"
									minDate={moment('2000-01-01')}
									maxDate={moment('2100-01-01')}
									value={runDate}
									onChange={(newValue) => {
										setRunDate(newValue);
										if (newValue.month() !== moment().month()) {
											setSinceLastRun(false);
										}
									}}
									renderInput={(params) => <TextField {...params} />}
									inputFormat="MMM YYYY"
								/>
							</LocalizationProvider>
							<Button variant="contained" color="primary" sx={{ m: 1 }} onClick={() => onRun({period: runDate, sinceLastRun: sinceLastRun, loadFullInventory: loadFullInventory, skipInventory: skipInventory, onOrderOnly: onOrderOnly})}>Run</Button>
						</Box>
						<Box
							sx={{
								px: 2,
								// py: 2,
								borderTop: '1px solid #ccc',
								fontWeight: 'normal',
							}}
						>
							<h3>
								Daily Run Settings
							</h3>
						</Box>
						<Box
							sx={{ 
								px: 2,
								pt: 2,
								borderTop: '1px solid #ccc',
							}}
						>
							<TextField
								label="Time Zone"
								select
								value={jobData.time_zone}
								onChange={(e) => setJobData({...jobData, 'time_zone': e.target.value})}
								SelectProps={{
									native: true,
								}}
								sx={{ width: 300 }}
							>
								<option value=""></option>
								{timezoneOptions.map((option) => (
									<option key={option} value={option}>
										{option}
									</option>
								))}
							</TextField>
						</Box>
						<Box id="action-buttons" sx={{ m: 2 }}>
							<Button variant="contained" color="primary" sx={{ m: 1 }} onClick={() => onSave(jobData)}>Save Setings</Button>
							{jobSettings.enabled ?
								<Button variant="contained" color="primary" sx={{ m: 1 }} onClick={() => toggleJob(false)}>Disable Daily Run</Button> :
								<Button variant="contained" color="primary" sx={{ m: 1 }} onClick={() => toggleJob(true)}>Enable Daily Run</Button>
							}
						</Box>
					</>
				: <Button variant="contained" color="primary" sx={{ m: 1 }} onClick={() => toggleJob(true)}>Create Job Settings</Button>}
			</Box>
		</Paper>
	);
}

export default JobSettings;